const trigger = document.querySelector(".imageFrame");
const target = document.querySelector(".boxElement");
const target2 = document.querySelector(".aboutMeImage");
const overlayTarget = document.querySelector(".overlay");

trigger.addEventListener("mouseover", () => {
  target.classList.add("translateElement");
  target2.classList.add("translateImage");
  overlayTarget.classList.add("animateOverlay");
});

trigger.addEventListener("mouseout", () => {
  target.classList.remove("translateElement");
  target2.classList.remove("translateImage");
  overlayTarget.classList.remove("animateOverlay");
});
